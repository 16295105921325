<template>
  <div ref="copyBox" class="copy-box">
    <slot v-if="$slots.default" />
    <el-form label-width="80px">
      <el-form-item v-for="(item, index) in list" :key="index" :label="item.label">
        <el-input :value="item.value" disabled />
        <el-button type="text" class="copy-box-btn" @click="handleCopy(item.value, $event)">
          <SvgIcon icon-class="copy" />
        </el-button>
      </el-form-item>
    </el-form>
    <div class="copy-box-footer">
      <el-button type="text" @click="handleCopyAll">
        一键复制全部
      </el-button>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon.vue"
import { Message } from "element-ui"
export default {
  components: { SvgIcon },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    copy(content, element) {
      this.$copyText(content, element).then(() => {
        Message.success("复制成功")
      }).catch(error => {
        Message.error("复制失败，请重试")
        console.log("error", error)
      })
    },
    handleCopy(content, event) {
      const target = event.currentTarget || event.target
      this.copy(content, target)
    },
    handleCopyAll() {
      const copyStr = this.list.map(item => `${item.label} ${item.value}`).join("\n")
      this.copy(copyStr, this.$refs.copyBox)
    }
  }
}

</script>

<style lang="scss">
.copy-box {
  .el-form-item__content {
    display: flex;

    .el-input {
      flex: 1;
    }

    .copy-box-btn {
      margin-left: 10px;
    }
  }

  .copy-box-footer {
    margin: 20px 0 0;
    text-align: right;

    .el-button--text {
      color: #4b88ff;
      font-weight: normal;
    }
  }
}

.message-box__credentials {
  width: 520px !important;
  max-width: 520px;

  .content {
    margin-bottom: 20px;
    line-height: 24px;
  }


  .el-form {
    .el-form-item {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
