<template>
  <div class="jy-form">
    <el-form ref="form"
             :inline="true"
             :model="formData"
             :disabled="disabled">
      <el-form-item v-for="(item,index) in itemData"
                    :key="'form'+index"
                    :label="item.label?item.label+':':''"
                    :prop="item.value">
        <el-input v-if="!item.type||item.type==='text'"
                  v-model="formData[item.value]"
                  v-trim
                  :placeholder="item.placeholder?item.placeholder:'请输入'+item.label"
                  clearable />

        <el-select v-if="item.type==='select'"
                   v-model="formData[item.value]"
                   :placeholder="item.placeholder?item.placeholder:'请选择'"
                   clearable>
          <el-option v-for="(ops,opsIndex) in item.options"
                     :key="'search'+opsIndex"
                     :label="item.defaultProps?ops[item.defaultProps.label]:ops.label"
                     :value="item.defaultProps?ops[item.defaultProps.value]:ops.value" />
        </el-select>

        <el-radio-group v-if="item.type==='radio'"
                        v-model="formData[item.value]"
                        clearable
                        @change="(val)=>{return change(val,item.value)}">
          <el-radio-button v-for="(ops,opsIndex) in item.options"
                           :key="'radio'+opsIndex"
                           class="radio-button"
                           :label="ops.value">
            {{ ops.label }}
          </el-radio-button>
        </el-radio-group>

        <el-date-picker v-if="item.type==='daterange'"
                        key="daterange"
                        v-model="formData[item.value]"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="['00:00:00', '23:59:59']"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        align="right"
                        clearable />

        <el-date-picker v-if="item.type==='monthrange'"
                        key="monthrange"
                        v-model="formData[item.value]"
                        type="monthrange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="['00:00:00', '23:59:59']"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        align="right"
                        clearable />
      </el-form-item>
      <el-form-item v-if="!disabled">
        <el-button type="primary" @click="onSubmit">
          查询
        </el-button>
        <el-button @click="onReset">
          重置
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import MockData from "../mock/search-forms"
export default {
  name: "JyForms",
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    form: {
      type: Object,
      default: () => {
        return {}
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      itemData: [],
      formData: {}
    }
  },
  watch: {
    form (val) {
      this.formData = val
    }
  },
  mounted () {
    this.itemData = this.data.length > 0 ? this.data : MockData[this.$route.name]
    this.formData = this.form
  },
  methods: {
    change (val, prop) {
      this.$emit("change", val, prop)
    },
    onSubmit () {
      this.$emit("submit", this.formData)
    },
    onReset() {
      this.$refs.form.resetFields()
      this.$emit("reset")
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.el-form-item) {
  margin-right: 20px;
  margin-bottom: 20px;

  .el-form-item__label,
  .el-input__inner {
    color: $color-gray-04;
  }

  .el-form-item__label {
    padding: 0 10px 0 0;
  }
}

.el-date-editor--daterange.el-input__inner {
  width: 300px;
}

:deep(.el-range-separator) {
  padding: 0;
}

// .el-button--default {
//   &:focus {
//     color: #606266;
//     background-color: #fff;
//     border-color: #dcdfe6;
//   }

//   &:hover {
//     color: #606266;
//     background-color: #fff;
//     border-color: #dcdfe6;
//   }

//   &:active {
//     color: #606266;
//     background-color: #fff;
//     border-color: #dcdfe6;
//   }
// }

</style>

<style lang="scss">
.radio-button {
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: #4b88ff;
    border-color: #4b88ff;
  }
}

</style>
