import Request from "@/api/normalService"
import { getAccessToken } from "@/utils/auth.js"
import qs from "qs"
import { get, post } from "@/api/http"

// 获取 token
// 数据类型必须为 form-data 格式
export function getOauthTokenApi (data) {
  return post("/oauth/token", qs.stringify(data))
}

// 检查 token 有效性，获取企业信息
export function checkOauthTokenApi () {
  return Request({
    method: "post",
    url: "/oauth/check_token",
    params: {
      token: getAccessToken()
    }
  })
}

/**
 * 获取当前用户企业信息
 * @returns
 */
export function getCompanyListApi () {
  return get("/oauth/user/getCurrentUser")
}

/**
 * 校验手机验证码
 * @params {object} params - 查询参数
 * @params {string} params.phoneNum - 手机号码
 * @params {string} params.code - 验证码
 * @returns
 *
 */
export function validateSmsCodeApi (data) {
  return post("/notify/captcha/verifySmsCaptcha", data)
}

/**
 * 获取图形验证码
 * @returns
 */
export function getCaptchaApi () {
  return Request({
    method: "get",
    url: "/notify/captcha/getPictureCaptcha"
  })
}

/**
 * 验证图形验证码
 * @param {string} code - 验证码
 * @returns
 */
export function validateCaptchaApi (code) {
  return Request({
    method: "post",
    url: "/notify/captcha/verifyPictureCaptcha",
    params: {
      captcha: code
    }
  })
}

/**
 * 验证企业是否停用
 * @param {string|number} companyId -  企业ID
 * @returns
 */
export function validateCompanyStatus (companyId) {
  return get(`/admin/company/status/${companyId}`)
}

/**
 * 获取服务条款
 * @returns
 */
export function getPrivacyFileUrlApi () {
  return Request({
    method: "get",
    url: "/base/address/url"
  })
}

/**
 * 获取手机验证码
 * @param {string} phoneNumber - 手机号码
 * @param {string} type - 验证码类别
 * @returns
 */
export function getSmsCodeApi (data) {
  return Request({
    method: "post",
    url: "/notify/captcha/sendSmsCaptcha",
    data
  })
}

export function logoutApi () {
  return get("/auth/logout")
}
