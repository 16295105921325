/**
 * 去除两边空格
 * 使用 <el-input v-model="xxx" v-trim></el-input>
 */

function getInput (el) {
  let inputEle
  if (el.tagName !== "INPUT") {
    inputEle = el.querySelector("input")
  } else {
    inputEle = el
  }
  return inputEle
}
function dispatchEvent (el, type) {
  const evt = document.createEvent("HTMLEvents")
  evt.initEvent(type, true, true)
  el.dispatchEvent(evt)
}
const Trim = {
  inserted: el => {
    const inputEle = getInput(el)
    const handler = function (event) {
      // const newVal = event.target.value.trim()
      const newVal = event.target.value.replace(/\s*/g, "")
      if (event.target.value !== newVal) {
        event.target.value = newVal
        dispatchEvent(inputEle, "input")
      }
    }
    if (!inputEle) { return }
    el.inputEle = inputEle
    el._blurHandler = handler
    inputEle.addEventListener("blur", handler)
  },
  unbind (el) {
    const { inputEle } = el
    if (!inputEle) { return }
    inputEle.removeEventListener("blur", el._blurHandler)
  }
}
// Trim.install = function (Vue) {
//   Vue.directive("trim", Trim)
// }
export default Trim
