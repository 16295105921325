import {
  getAccessToken,
  setAccessToken,
  setRefreshToken,
  removeAccessToken,
  removeRefreshToken,
  removeCompanyId,
  removePassword,
  setGender,
  removeGender,
  getGender,
  getCompany,
  setCompany,
  removeCompany
} from "@/utils/auth"

import { PURGE_AUTH, SET_AUTH, SET_GENDER, SET_ACCOUNTLIST } from "./mutation.type"
import { getOauthTokenApi, logoutApi } from "@/api/auth"

export const state = {
  isAuthenticated: !!getAccessToken(),
  expirationIn: "", // 过期时间
  gender: +getGender()
  // companyList: getCompany()
}

export const mutations = {
  [SET_AUTH] (state, user) {
    state.isAuthenticaed = true
    state.gender = user.gender
    setGender(user.gender)
    setAccessToken(user.access_token)
    setRefreshToken(user.refresh_token)
  },
  [PURGE_AUTH] (state) {
    state.isAuthenticated = false
    removeCompanyId()
    removePassword()
    removeAccessToken()
    removeRefreshToken()
    removeGender()
    removeCompany()
  },
  [SET_GENDER] (state, payload) {
    state.gender = +payload
  },
  [SET_ACCOUNTLIST] (state, payload) {
    // state.companyList = payload
    setCompany(payload)
  }
}

export const actions = {
  login ({ commit }, params) {
    return new Promise((resolve, reject) => {
      getOauthTokenApi(params)
        .then(res => {
          commit(SET_AUTH, res)

          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  resetToken ({ commit }) {
    commit(PURGE_AUTH)
  },

  setAccount ({ commit }, params) {
    commit(SET_ACCOUNTLIST, params)
  },

  logout ({ commit }) {
    return new Promise((resolve) => {
      logoutApi().then(() => {
        commit(PURGE_AUTH)
        resolve()
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
