<template>
  <div class="jy-drawer">
    <el-drawer :title="title" :visible.sync="drawerVisible" direction="rtl" :size="size" :wrapper-closable="isCheck"
      :before-close="onCancel">
      <div v-loading="loading" element-loading-spinner="el-icon-loading" element-loading-text="操作执行中...请耐心等待，勿操作"
        class="jy-drawer__content">
        <div class="jy-drawer__content-container">
          <slot />
        </div>
        <div v-if="defaultButton" class="jy-drawer__footer">
          <el-button v-if="title === '上传退费凭证' || title === '批量编辑' || title === '关联对象' || title === '推送规则' || title == '查看' || title === '编辑' || title === '新增' || title === '添加' || title === '关联建筑' || title === '选择设备' || title === '手动处理'"
            @click="onCancel">
            取消
          </el-button>
          <el-button v-if="title !== '查看'" type="primary" @click="onConfirm">
            确定
          </el-button>
        </div>
        <div v-if="$slots.footer && !defaultButton" class="jy-drawer__footer">
          <slot name="footer" />
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
export default {
  name: "JyDrawer",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    defaultButton: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    isCheck: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "830px"
    }
  },
  data () {
    return {
      drawerVisible: false
    }
  },
  watch: {
    visible: function (val, oldVal) {
      this.drawerVisible = val
    }
  },
  methods: {
    onConfirm () {
      this.$emit("confirm")
    },
    onCancel () {
      this.$emit("cancel")
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.el-drawer__header) {
  height: 40px;
  padding: 0 20px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #4b88ff;
}

.jy-drawer {
  &__content {
    height: calc(100% - 2px);
  }

  &__content-container {
    height: calc(100% - 83px);
    padding: 0 20px;
    overflow: auto;
  }

  &__footer {
    padding: 20px;
    margin-top: 10px;
    text-align: right;
    border-top: 1px solid $color-gray-01;
  }
}
</style>
