import axios from "axios"
import { getAccessToken, getCompanyId } from "@/utils/auth"
import { createRequestCache } from "@/utils/request-deduplication"

// 环境的切换
const baseURL = window.location.origin
export function createService () {
  const service = axios.create({
    baseURL,
    timeout: 60000,
    headers: { "serviceType": SERVICE_TYPE }
  })

  //  让请求在浏览器中允许跨域携带cookie
  service.defaults.withCredentials = true
  return service
}

// 请求缓存
export const requestCache = {}

// 设置请求拦截器
export function setReqInterceptors (instance, requestCache) {
  instance.interceptors.request.use(
    config => {
      // 创建请求缓存，并判断是否重复请求，若为重复缓存，则取消请求操作
      createRequestCache(requestCache, config)

      // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
      // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
      const accessToken = getAccessToken()

      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`
        config.headers["companyId"] = getCompanyId()
      }

      return config
    },
    error => {
      return Promise.reject(error)
    })
}
