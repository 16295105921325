import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
// 结合 Webpack loader - svg-sprite-loader, 引入 SVG Sprit
import "./icons"

import "./api/axios"

import * as http from "./api/http"
import api from "./api/api"
Vue.prototype.$api = api
Vue.prototype.$http = http

import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import "./assets/styles/element-variables.scss"
Vue.use(ElementUI, { size: "small" })


import VueClipBoard from "vue-clipboard2"
Vue.use(VueClipBoard)

Vue.config.productionTip = false

import JyTable from "@/components/JyTable"
import JyForms from "@/components/JyForms"
import JyDialog from "@/components/JyDialog"
import JyDrawer from "@/components/JyDrawer"
import JyDrawerFormSubmit from "@/components/JyDrawerFormSubmit"
import JyDrawerFormCheck from "@/components/JyDrawerFormCheck"
import JyPageContentLayout from "@/components/JyPageContentLayout"
import JyTreeLeft from "@/components/JyTreeLeft"
import SvgIcon from "@/components/SvgIcon.vue"
const components = [JyTable, JyForms, JyDialog, JyDrawer, JyDrawerFormSubmit, JyDrawerFormCheck, JyPageContentLayout, JyTreeLeft, SvgIcon]
components.map(item => {
  Vue.component(item.name, item)
})

import Trim from "./directives"
Vue.directive("trim", Trim)

import "./assets/styles/common.scss"

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app")
