// 登录 token
const accessToken = "Access-Token"
// 刷新 token
const refreshToken = "Refresh-Token"
// 企业 ID
const companyId = "Company-Id"
// 用户密码
const password = "Password"
// 用户性别
const gender = "Gender"
// 企业列表
const company = "Company"

// accesstoken
export const getAccessToken = function () {
  return window.localStorage.getItem(accessToken)
}

export const setAccessToken = function (token) {
  window.localStorage.setItem(accessToken, token)
}

export const removeAccessToken = function () {
  window.localStorage.removeItem(accessToken)
}

// refreshtoken
export const getRefreshToken = function () {
  return window.localStorage.getItem(refreshToken)
}

export const setRefreshToken = function (token) {
  window.localStorage.setItem(refreshToken, token)
}

export const removeRefreshToken = function () {
  window.localStorage.removeItem(refreshToken)
}

// companyid
export const getCompanyId = function () {
  return JSON.parse(window.localStorage.getItem(companyId))
}

export const setCompanyId = function (id) {
  window.localStorage.setItem(companyId, JSON.stringify(id))
}

export const removeCompanyId = function () {
  window.localStorage.removeItem(companyId)
}

// password
export const getPassword = function () {
  return window.localStorage.getItem(password)
}

/**
 * @param {boolean} bool - 是否设置密码
 * @returns
 */
export const setPassword = function (bool) {
  window.localStorage.setItem(password, bool)
}

export const removePassword = function () {
  window.localStorage.removeItem(password)
}

// gender
export const getGender = function () {
  return window.localStorage.getItem(gender)
}

/**
 * @param {boolean} bool - 是否设置密码
 * @returns
 */
export const setGender = function (genderNum) {
  window.localStorage.setItem(gender, genderNum)
}

export const removeGender = function () {
  window.localStorage.removeItem(gender)
}

export const getCompany = function () {
  return JSON.parse(window.localStorage.getItem(company))
}

export const setCompany = function (payload) {
  window.localStorage.setItem(company, JSON.stringify(payload))
}

export const removeCompany = function () {
  window.localStorage.removeItem(company)
}
