import { validatePhoneNumber } from "@/utils"

const emailReg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
const AlarmRules = {
  deviceIdList: [
    { required: true, message: "请选择", trigger: "blur" }
  ],
  pushRuleId: [
    { required: true, message: "请选择", trigger: "blur" }
  ]
}

const CostRules = {
  eventName: [
    { required: true, message: "请输入(至多20字)", trigger: "blur" },
    { min: 1, max: 20, message: "至多20字", trigger: "blur" }
  ],
  amount: [// 预警
    { required: true, pattern: /^[1-9][0-9]*$/, message: "请输入大于0的数字", trigger: "blur" }
  ],
  amount1: [// 透支
    { required: true, pattern: /^[0-9]*$/, message: "请输入大于等于0的数字", trigger: "blur" }
  ],
  ruleType: [
    { required: true, message: "请选择规则类型", trigger: "blur" }
  ],
  buildingId: [
    { required: true, message: "请关联建筑", trigger: "blur" }
  ],
  roleName: [
    { required: true, message: "请选择角色", trigger: "change" }
  ]
}

const PushRules = {
  ruleName: [
    { required: true, message: "请输入", trigger: "blur" },
    { max: 20, message: "至多20字", trigger: "blur" }
  ],
  checkList: [
    { required: true, message: "推送对象必选", trigger: "blur" }
  ],
  userList: [
    { required: true, message: "请填写相关信息", trigger: "blur" }
  ],
  phoneNum: [
    { required: true, validator: validatePhoneNumber, trigger: "blur" }
  ],
  email: [
    { pattern: emailReg, message: "邮箱格式错误", trigger: "blur" }
  ]
}

const BuildManagement = {
  buildingName: [
    { required: true, message: "建筑名称不能为空", trigger: "blur" }
  ],
  areaSize: [
    { required: true, message: "面积不能为空", trigger: "blur" },
    { pattern: /^([1-9][0-9]{0,5}(\.\d{1,2})?|(0\.\d{1,2})?|999999.99)$/, message: "面积应大于0且小于1 000 000，最多可保留两位小数", trigger: "blur" }
  ],
  personCount: [
    { required: true, message: "人数为正整数", trigger: "blur" },
    { pattern: /^[1-9]\d*$/, message: "人数为正整数", trigger: "blur" }
  ]
}

const ElectricityMeter = {
  deviceType: [
    { required: true, message: "电表类型不能为空", trigger: "change" }
  ],
  energyType: [
    { required: true, message: "能源类型不能为空", trigger: "blur" }
  ],
  deviceNumber: [
    { required: true, message: "电表号不能为空", trigger: "blur" },
    {
      pattern: /^[a-zA-Z0-9]{1,25}$/,
      message: "电表号只支持字母和数字,长度最多25位",
      trigger: "blur"
    }
  ],
  comTypeList: [
    { required: true, message: "通讯类型不能为空", trigger: "blur" }
  ],
  ratio: [
    {
      pattern: /^[1-9]\d*$/,
      message: "CT变比必须是正整数",
      trigger: "blur"
    }
  ],
  initAmount: [{
    pattern: /^[+]{0,1}(\d+)$/,
    message: "预留金额必须是非负整数",
    trigger: "blur"
  }],
  maxPower: [
    {
      pattern: /^([1-9][0-9]{0,5}(\.\d{1,2})?|(?!0(\.0+)?$)(0\.\d{1,2})?)$/,
      message: "最大功率必须是大于0的数字(小数点前6位后2位)",
      trigger: "blur"
    }
  ]
}

const ElectricityPrice = {
  energyPriceName: [
    { required: true, message: "收费方案名称不能为空", trigger: "blur" }
  ],
  priceType: [
    { required: true, message: "必填", trigger: "change" }
  ]
}

const TenantManagement = {
  phoneNum: [
    { required: true, message: "手机号不能为空", trigger: "blur" },
    {
      message: "手机号格式有误",
      pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
      trigger: "blur"
    }
  ],
  fullName: [
    { required: true, message: "请输入姓名", trigger: "blur" },
    { min: 1, max: 20, message: "至多20字", trigger: "blur" }
  ],
  gender: [{ required: true, message: "请选择性别", trigger: "change" }],
  email: [
    {
      pattern: emailReg,
      message: "邮箱格式错误",
      trigger: "blur"
    },
    { max: 50, message: "至多50字", trigger: "blur" }
  ]
}

const RoleManagement = {
  roleName: [
    { required: true, message: "角色名不能为空", trigger: "blur" }
  ],
  resourceIdList: [
    { required: true, message: "请分配相应权限", trigger: "blur" }
  ],
  buildingIdList: [
    { required: true, message: "请分配相应权限", trigger: "blur" }
  ]
}

const UserManagement = {
  userName: [
    { pattern: /(?=.*[A-Za-z])^[A-Za-z0-9][a-zA-Z0-9_-]{3,19}$/, message: "支持字母、数字、- _；包含字母且不以- _开头(4-20位)", trigger: "blur" }
  ],
  phoneNum: [
    // { required: true, message: "手机号不能为空", trigger: "blur" },
    { pattern: /^1(3|4|5|6|7|8|9)\d{9}$/, message: "手机号格式错误", trigger: "blur" }
  ],
  fullName: [
    { required: true, message: "请输入姓名", trigger: "blur" }
  ],
  email: [
    { pattern: emailReg, message: "邮箱格式错误", trigger: "blur" }
  ]
}

const RateTimeConfig = {
  name: [
    { required: true, message: "请输入时段名称", trigger: "blur" }
  ],
  period: [
    { required: true }
  ]
}
const PayRefundRecord = {
  content: [
    { required: true, message: "请输入处理描述", trigger: ["blur", "change"] }
  ]
}
export default { AlarmRules, CostRules, PayRefundRecord, PushRules, BuildManagement, ElectricityMeter, ElectricityPrice, TenantManagement, RoleManagement, UserManagement, RateTimeConfig }
