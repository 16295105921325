/*
  ******************* 请求去重处理
*/

// 获取请求内容字符串
export const generateDataStr = config => {
  const { method, data, params } = config
  let dataStr = ""
  if (method === "get" && params) {
    dataStr = typeof params !== "string" ? JSON.stringify(params) : params
  }
  if (method === "post" && data) {
    dataStr = typeof data !== "string" ? JSON.stringify(data) : data
  }
  return dataStr
}

// 自定义错误类型
export class RequestError extends Error {
  constructor (message) {
    super()
    this.message = message
    this.name = this.constructor.name
  }
}

/**
 * 创建请求缓存
 * @param {Obejct} requestCache - 请求缓存
 * @param {Object} config - axios 配置
 * @returns
 */
export function createRequestCache (requestCache, config) {
  // 清除无用的cache
  Object.keys(requestCache).forEach(item => {
    const { isResponse, timestamp } = requestCache[item]
    if (isResponse && Date.now() - timestamp >= 300) {
      delete requestCache[item]
    }
  })

  // 阻止频繁请求和重复请求
  // unique 为 true - 关闭请求去重处理，如果开启请检查代码可用性
  const { method, url, data, unique } = config
  const dataStr = generateDataStr(config)
  const hash = `${method}${url}${dataStr}${unique ? Math.random() : ""}`

  // 重复请求
  if (requestCache[hash]) {
    const { timestamp, isResponse, method, url } = requestCache[hash]
    // 距离上次请求不足300毫秒
    if (Date.now() - timestamp < 300) {
      console.warn("请求过于频繁", method, url)
      throw new RequestError("请求过于频繁")
    }

    // 请求尚未返回
    if (!isResponse) {
      console.warn("请勿重复提交", method, url)
      throw new RequestError("请勿重复提交")
    }
  }
  // 新请求初始化
  requestCache[hash] = {
    method,
    url,
    data,
    isResponse: false,
    timestamp: Date.now()
  }
}

/**
 * 处理缓存
 * @param {Obejct} requestCache - 请求缓存
 * @param {Object} config - axios 配置
 * @returns
 */
export function handleRequestCache (requestCache, config) {
  // 如果请求返回，对应的requestKey.isResponse设置为true
  const { method, url } = config
  const dataStr = generateDataStr(config)
  const hash = `${method}${url}${dataStr}`

  if (requestCache[hash]) {
    requestCache[hash].isResponse = true
  }
}
