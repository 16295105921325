import { DeviceType, BalanceStatus, RecordState, BrakeControl, Continuous, AlarmLevel, IsRelatedDevice, RuleType, IsRelated, StatisticsType, PriceType, EnergyPriceStatus, Gender, PAY_WAY, RechargeType } from "./index"

const PayManagement = [
  { label: "租户信息", value: "userInfo" },
  { label: "电表号", value: "deviceNumber" },
  { label: "余额状态", value: "balanceState", type: "select", options: BalanceStatus }
]

const RefundManagement = [
  { label: "建筑名称", value: "buildingName" },
  { label: "租客信息", value: "userInfo" },
  { label: "电表号", value: "deviceNumber" },
  { label: "退费状态", value: "recordState", type: "select", options: RecordState },
  { label: "最近操作时间", value: "time", type: "daterange" }
]
//
const GateControl = [
  { label: "建筑名称", value: "buildingName" },
  { label: "租户信息", value: "userInfo" },
  { label: "电表号", value: "deviceNumber" },
  { label: "闸控状态", value: "brakeControl", type: "select", options: BrakeControl },
  { label: "保电状态", value: "continuous", type: "select", options: Continuous },
  { label: "最后通讯时间", value: "time", type: "daterange" }
]

const PowerProtection = [
  { label: "建筑名称", value: "buildingName" },
  { label: "租户信息", value: "userInfo" },
  { label: "电表号", value: "deviceNumber" },
  { label: "是否保电", value: "continuous", type: "select", options: Continuous },
  { label: "最后通讯时间", value: "time", type: "daterange" }
]

const AbnormalEvent = [
  { label: "建筑名称", value: "buildingName" },
  { label: "租户信息", value: "tenantInfo" },
  { label: "电表号", value: "deviceNumber" },
  { label: "告警等级", value: "alarmLevel", type: "select", options: AlarmLevel },
  { label: "发生时间", value: "time", type: "daterange" }
]

const AlarmRules = [
  { label: "告警规则名称", value: "eventName" },
  { label: "告警等级", value: "alarmLevel", type: "select", options: AlarmLevel },
  { label: "是否关联对象", value: "isRelatedDevice", type: "select", options: IsRelatedDevice }
]

const PushRules = [
  { label: "推送规则名称", value: "pushRuleName" },
  { label: "关联状态", value: "isRelated", type: "select", options: IsRelated }
]

const CostRules = [
  { label: "费控规则名称", value: "eventName" },
  { label: "规则类型", value: "ruleType", type: "select", options: RuleType }
]

const ShortMessageRecord = [
  { label: "建筑名称", value: "buildingName" },
  { label: "租户信息", value: "tenantInfo" },
  { label: "电表号", value: "deviceNumber" },
  { label: "催缴时间", value: "time", type: "daterange" }
]

const ReadingSettlementReport = [
  { label: "建筑名称", value: "buildingName" },
  { label: "租客信息", value: "userInfo" },
  { label: "电表号", value: "deviceNumber" },
  { label: "时间", value: "statisticsType", type: "radio", options: StatisticsType },
  { label: "", value: "time", type: "daterange" }
]
// 缴费统计
const PaymentStatistics = [
  // { label: "", value: "statisticsType", type: "radio", options: StatisticsType },
  { label: "统计时间", value: "time", type: "daterange" }
]

// 费用明细
const FeeSchedule = [
  { label: "端口", value: "deviceType", type: "select", options: DeviceType },
  { label: "操作人", value: "deviceNumber" },
  { label: "统计时间", value: "buildingName" },
  { label: "", value: "time", type: "daterange" }
]

// 缴费分类汇总
const PaymentClassification = [
  { label: "付款方式", value: "payType", type: "select", options: PAY_WAY },
  { label: "操作人", value: "person" },
  { label: "电表类型", value: "device", type: "select", options: DeviceType },
  { label: "充值类型", value: "RechargeType", type: "select", options: RechargeType },
  { label: "统计时间", value: "time", type: "daterange" }
]

const EnergyAnalysis = [
  { label: "统计时间", value: "time", type: "daterange" }
]

const ElectricityMeter = [
  { label: "电表类型", value: "deviceType", type: "select", options: DeviceType },
  { label: "电表号", value: "deviceNumber" },
  { label: "建筑信息", value: "buildingName" },
  { label: "时间", value: "time", type: "daterange" }
]

const ElectricityPrice = [
  { label: "收费方案名称", value: "energyPriceName" },
  { label: "电价类型", value: "priceType", type: "select", options: PriceType },
  { label: "时间", value: "time", type: "daterange" }
]

const ElectricityPriceDistribution = [
  { label: "建筑名称", value: "buildingName" },
  { label: "电表号", value: "deviceNumber" },
  { label: "任务状态", value: "energyPriceStatus", type: "select", options: EnergyPriceStatus },
  { label: "任务创建时间", value: "time", type: "daterange" }
]

const TenantManagement = [
  { label: "姓名", value: "fullName" },
  { label: "性别", value: "gender", type: "select", options: Gender },
  { label: "手机号", value: "phoneNum" },
  { label: "租用建筑", value: "buildingName" },
  { label: "添加时间", value: "time", type: "daterange" }
]

const RateTimeConfig = [
  { label: "时段名称", value: "name" },
  { label: "时间", value: "time", type: "daterange" }
]
const PeriodDistribution = [
  { label: "电表号", value: "deviceNumber" },
  { label: "任务状态", value: "taskState", type: "select", options: EnergyPriceStatus },
  { label: "任务创建时间", value: "time", type: "daterange" }
]
const RoleManagement = [
  { label: "角色名", value: "roleName" }
]

const UserManagement = [
  { label: "用户名", value: "userName" },
  { label: "手机号", value: "phoneNum" },
  { label: "姓名", value: "fullName" }
]

const PayRefundRecord = [{
  value: "transactionNumber", // 充值退费编号
  label: "充值/退费编号"
}, {
  value: "buildingName", // 建筑名称
  label: "建筑名称"
}, {
  value: "deviceNumber", // 电表号
  label: "电表号"
}, {
  value: "operateType", // 操作类型
  label: "操作类型",
  type: "select",
  options: [
    {
      value: 1,
      label: "在线充值"
    }, {
      value: 2,
      label: "在线退费"
    }, {
      value: 3,
      label: "微信充值"
    }, {
      value: 4,
      label: "微信退费"
    }, {
      value: 5,
      label: "预留金额"
    }]
}, {
  value: "payWay", // 付款方式
  label: "付款方式",
  type: "select",
  options: [
    {
      value: 1,
      label: "转账"
    }, {
      value: 2,
      label: "汇款"
    }, {
      value: 3,
      label: "现金"
    }, {
      value: 4,
      label: "免费补费"
    }, {
      value: 5,
      label: "微信"
    }, {
      value: 6,
      label: "出厂配置"
    }]
}, {
  value: "transactionState", // 交易状态
  label: "状态",
  type: "select",
  options: [
    {
      value: 1,
      label: "交易成功"
    }, {
      value: 2,
      label: "已全额退款"
    }, {
      value: 3,
      label: "退款失败"
    }, {
      value: 4,
      label: "交易关闭"
    }, {
      value: 5,
      label: "交易中"
    }]
}, {
  value: "time", // 充值退费时间
  type: "daterange",
  label: "充值/退费时间"
}]

const OptLog = [
  { label: "平台", value: "platform" },
  { label: "用户名", value: "operatorUsername" },
  { label: "操作人姓名", value: "operatorFullName" },
  { label: "时间", value: "time", type: "daterange" }
]

export default {
  PayManagement,
  RefundManagement,
  GateControl,
  PowerProtection,
  AbnormalEvent,
  AlarmRules,
  PushRules,
  CostRules,
  ShortMessageRecord,
  ReadingSettlementReport,
  PaymentStatistics,
  FeeSchedule,
  PaymentClassification,
  EnergyAnalysis,
  ElectricityMeter,
  ElectricityPrice,
  ElectricityPriceDistribution,
  TenantManagement,
  RateTimeConfig,
  PeriodDistribution,
  RoleManagement,
  UserManagement,
  PayRefundRecord,
  OptLog
}
