<template>
  <div class="jy-drawer-form" v-bind="$attrs">
    <el-form ref="form" :model="form" :rules="rules" :label-width="labelWidth">
      <el-form-item v-for="(item,index) in itemData" :key="'form'+index" :label="item.label+':'"
        :required="item.required" :class="noStar ? 'noStar' : item.label === 'noshow' ? 'noShow' : ''"
        :prop="item.value">
        <template #label>
          <div v-if="item.label ==='CT变比'">
            CT变比
            <el-tooltip effect="dark" content="电费的充值/退费与用电量的统计会根据CT变比进行换算，请根据实际情况如实填写，请谨慎修改。设备激活后无法修改，默认为1"
              placement="top-start">
              <!-- <i class="el-icon-warning" style="color: #e6ad00;" /> -->
              <SvgIcon icon-class="question" width="14px" style="margin: 0 2px 0 -2px;" />
            </el-tooltip>:
          </div>
          <div v-else-if="item.label ==='预留金额'">
            预留金额
            <el-tooltip effect="dark" content="预留金额为电表出厂时预设的金额，将在首次在线充值电费时扣除预留金额" placement="top-start">
              <!-- <i class="el-icon-warning" style="color: #e6ad00;" /> -->
              <SvgIcon icon-class="question" width="14px" style="margin: 0 2px 0 -2px;" />
            </el-tooltip>:
          </div>
          <div v-else-if="item.label === '预计生效时间'">
            预计生效时间
            <el-tooltip effect="dark" content="设置时段配置的生效时间。不设置生效时间，按照默认规则：成功下发时段配置第二天凌晨生效。" placement="top-start">
              <!-- <i class="el-icon-warning" style="color: #e6ad00;" /> -->
              <SvgIcon icon-class="question" width="14px" style="margin: 0 2px 0 -2px;" />
            </el-tooltip>:
          </div>
        </template>
        <el-input v-if="!item.type" :value="formData[item.value]" :disabled="disabled[item.value]"
          :placeholder="item.placeholder || '请输入'" clearable
          @input="(val) => handleInput(val, item.value, item.maxlength)"
          @change="(val)=>{return change(val,item.value)}" />
        <el-input v-if="item.type==='number'" v-model="formData[item.value]" v-trim placeholder="请输入" type="number"
          :min="0" clearable />
        <el-input v-if="item.type==='textarea'" v-model="formData[item.value]" v-trim :disabled="disabled[item.value]"
          :maxlength="item.maxlength" :rows="item.rows?item.rows:4" placeholder="请输入" type="textarea" show-word-limit
          :autosize="{ minRows: 4, maxRows: 6 }" clearable />
        <el-select v-if="item.type==='select'" v-model="formData[item.value]" :disabled="disabled[item.value]"
          :multiple="item.multiple?true:false" placeholder="请选择" :clearable="item.clearable==='display'?false:true"
          @change="changeSelect(formData[item.value])">
          <el-option v-for="(ops,opsIndex) in options[item.value]" :key="'ops'+opsIndex"
            :label="item.defaultProps?ops[item.defaultProps.label]:ops.label"
            :value="item.defaultProps?ops[item.defaultProps.value]:ops.value" />
        </el-select>
        <el-date-picker v-if="item.type === 'datepicker'" v-model="formData[item.value]" type="date"
          :value-format="item.format" placeholder="请选择日期" :picker-options="item.options">
        </el-date-picker>
        <el-switch v-if="item.type==='switch'" v-model="formData[item.value]" active-color="#13ce66"
          inactive-color="#dcdfe6" />
        <el-radio-group v-if="item.type==='radio'" v-model="formData[item.value]">
          <el-radio v-for="(ops,opsIndex) in options[item.value]" :key="'ops'+opsIndex"
            :label="item.defaultProps?ops[item.defaultProps.value]:ops.value">
            {{ item.defaultProps?ops[item.defaultProps.label]:ops.label }}
          </el-radio>
        </el-radio-group>
        <el-checkbox-group v-if="item.type==='checkbox'" v-model="formData[item.value]" :disabled="disabled[item.value]"
          clearable>
          <el-checkbox v-for="(ops,opsIndex) in options[item.value]" :key="'checkbox'+opsIndex"
            :label="item.defaultProps?ops[item.defaultProps.value]:ops.value"
            :title="item.defaultProps?ops[item.defaultProps.label]:ops.label">
            {{ item.defaultProps?ops[item.defaultProps.label]:ops.label }}
          </el-checkbox>
        </el-checkbox-group>
        <el-cascader v-if="item.type==='cascader'" v-model="formData[item.value]" :disabled="disabled[item.value]"
          :options="options[item.value]" :show-all-levels="item.showAllLevels"
          :props="item.defaultProps?item.defaultProps:{checkStrictly: true}" placeholder="请选择" clearable />
        <!-- 费率页面特供使用table -->
          <el-table v-if="item.type==='table'" ref="formTable" :data="formData[item.value]" :height="item.height"
            header-cell-class-name="header-cell" cell-class-name="row-cell" @selection-change="handleTableSelect">
            <el-table-column v-if="item.checked" type="selection" width="85" key="sel" />
            <el-table-column v-else type="index" label="序号" width="65" key="ind" />
            <el-table-column v-for="(table,tIndex) in item.columns" :key="'table'+tIndex" :prop="table.prop"
              :label="table.label" />
          </el-table>
          <el-pagination v-if="item.type === 'table'" :current-page="item.pageNum" :page-sizes="[5, 20, 50, 100]" :page-size="item.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="Number(item.total)" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" />
        <el-upload v-if="item.type==='upload'" ref="upload" action="#" list-type="picture-card" :auto-upload="false"
          :class="uploadDisable ===false?'':'uploadDisabled'" show-file-list :on-change="beforeAvatarUpload"
          accept="image/png, image/jpg, image/jpeg" :limit="limit">
          <i slot="default" class="el-icon-plus" />
          <div slot="file" slot-scope="{file}">
            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
            <span class="el-upload-list__item-actions">
              <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                <i class="el-icon-zoom-in" />
              </span>
              <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                <i class="el-icon-delete" />
              </span>
            </span>
          </div>
        </el-upload>
        <slot v-if="item.type==='slot'" :name="item.value" />
      </el-form-item>
    </el-form>

    <el-dialog :visible.sync="dialogVisible" :modal="false">
      <img :src="dialogImageUrl" alt="" style="width: 100%;">
    </el-dialog>
  </div>
</template>
<script>
import MockData from "@/mock/drawer-form-submit"
import Rules from "@/mock/drawer-form-submit-rules"
export default {
  name: "JyDrawerFormSubmit",
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    disabled: {
      type: Object,
      default: () => {
        return {}
      }
    },
    form: {
      type: Object,
      default: () => {
        return {}
      }
    },
    options: {
      type: Object,
      default: () => {
        return {}
      }
    },
    labelWidth: {
      type: String,
      default: "110px"
    },
    visible: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 5
    }
  },
  data () {
    return {
      itemData: [],
      formData: {},
      rules: Rules[this.$route.name],
      dialogImageUrl: "",
      dialogVisible: false,
      uploadDisable: false,
      fileListData: [],
      drawerVisible: false,
      noStar: false,
    }
  },
  watch: {
    form (val) {
      this.formData = val
    },
    fileListData (val) {
      this.fileListData = val
    },
    visible: function (val, oldVal) {
      console.log("valvalval", val, oldVal)
      this.drawerVisible = val
      if (val === false) {
        console.log("this.$refs.upload", this.$refs, this.$refs.upload)
        this.$refs.upload && this.$refs.upload[0].clearFiles()
        this.uploadDisable = false
      }
    },
    "$attrs.title": {
      handler: function (val) {
        if ((this.$route.name === "UserManagement" || this.$route.name === "RateTimeConfig") && val === "查看") {
          this.noStar = true
        } else {
          this.noStar = false
        }
      },
      immediate: true
    }
  },
  created () {
    this.itemData = this.data.length > 0 ? this.data : MockData[this.$route.name]
    // if (this.$route.name === "RateTimeConfig" && this.$attrs.title !== "查看") {
    //   this.itemData = this.itemData.slice(0, 2)
    // }
    this.formData = this.form
  },
  methods: {
    handleInput(val, key, maxlength) {
      // console.log(val,99);
      const newVal = val.trim()
      if (newVal.length > maxlength) return
      this.formData[key] = newVal
    },
    change(val, prop) {
      // console.log(val,prop,88);
      this.$emit("change", val, prop)
    },
    // 电表管理-新增时-选择电表类型为单相表时，CT变比置灰并且值为1
    changeSelect (val) {
      this.$emit("changeSelect", val)
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleRemove (file) {
      const res = this.fileListData.filter(item => {
        return item.uid !== file.uid
      })
      this.fileListData = res
      if (this.fileListData.length <= this.limit) {
        this.uploadDisable = false
      }
      const uploadFiles = this.$refs.upload[0].uploadFiles
      for (var i = 0; i < uploadFiles.length; i++) {
        if (uploadFiles[i]["url"] === file.url) {
          uploadFiles.splice(i, 1)
        }
      }
    },
    beforeAvatarUpload (file, fileList) {
      const FILE_TYPES = ["image/jpeg", "image/png", "image/jpg"]
      const FILE_NAME_TYPES = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"]
      const fileNameType = (file.name.split(".") || []).slice(-1)[0]
      const isJPG = FILE_TYPES.includes(file.raw.type) && FILE_NAME_TYPES.includes(fileNameType)
      const isLt2M = Number(file.size / 1024 / 1024).toFixed(2) < 5

      if (!isJPG) {
        this.$message.error("上传图片仅支持 JPG 格式或 JPEG 格式或 PNG 格式!")
        return this.handleRemove(file)
      }
      if (!isLt2M) {
        this.$message.error("图片不超过5M!")
        return this.handleRemove(file)
      }
      // if (!isJPG || !isLt2M) {
      //   return this.handleRemove(file)
      // }
      this.fileListData = fileList
      if (fileList.length === this.limit) {
        this.uploadDisable = true
        this.$message.success(`最多上传${this.limit}张图片`)
      } else {
        this.uploadDisable = false
      }
    },
    handleTableSelect(selection) {
      this.$emit("select-change", selection)
    },
    handleSizeChange(val) {
      this.$emit("changeSize", val)
    },
    handleCurrentChange(val) {
      this.$emit("changeNum", val)
    }
  }
}
</script>
<style lang="scss" scoped>
.jy-drawer-form {
  max-height: 100%;
  height: 100%;
  .el-form {
    height: 100%;
    .el-form-item {
      margin-bottom: 15px;
    }
  }

  :deep(.el-checkbox__label) {
    width: 160px;
    padding-bottom: 5px;
    overflow: hidden;
    line-height: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
  }
  :deep(.el-table) {
    .el-table__header-wrapper {
      .el-checkbox {
        &::after {
          content: "全选";
          position: absolute;
          top: 0;
          left: 20px;
          width: 30px;
          height: 12px;
        }
      }
    }
  }

  :deep(.el-upload--picture-card) {
    background: rgba(210, 210, 210, 0.20);
    border: 1px dashed #d2d2d2;
    border-radius: 4px;
    width: 120px;
    height: 120px;
    i {
      color: #999;
      vertical-align: super;
    }
    &:hover {
      background: rgba(75, 136, 255, 0.05);
      border: 1px dashed #4b88ff;
      i {
        color: #4b88ff;
      }
    }
  }
}

.uploadDisabled {
  :deep(.el-upload--picture-card) {
    display: none;

  }
  :deep(.el-upload-list__item) {
    width: 120px;
    height: 120px;
  }
}
.noStar {
  :deep(.el-form-item__label) {
    &::before {
      display: none;
    }
  }
}
.noShow {
  margin-bottom: 0;
  height: calc(100% - 53px);
  >:deep(.el-form-item__label) {
    display: none;
  }
  :deep(.el-form-item__content) {
    margin-left: 0!important;
    height: 100%;
  }
}
</style>
