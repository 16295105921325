<template>
  <div id="table" class="jy-table" :style="{height:height}">
    <el-table ref="multipleTable" v-loading="loading" :data="data"
      :show-summary="tableSummary==='PaymentStatistics'||tableSummary==='FeeSchedule'?true:false"
      :summary-method="getSummaries" header-cell-class-name="header-cell" cell-class-name="row-cell"
      :height="isPagination?'calc(100% - 20px - 32px)':'100%'" style="width: 100%;"
      @selection-change="handleSelectionChange" @select="handleSelect" @select-all="handleSelectAll">
      <el-table-column v-if="isSelected" v-model="Selected" :fixed="isPagination" type="selection" />
      <el-table-column type="index" width="60" :fixed="isPagination" label="序号" />
      <el-table-column v-for="(item,index) in cols" :key="item.prop" :fixed="index===0&&isPagination?true:false"
        :prop="item.prop" :label="item.label" :min-width="item.width?item.width:0"
        :show-overflow-tooltip="!item.showOverflowTooltip">
        <template slot-scope="scope">
          <!--用户账户管理-状态 #555黑色  #FF4B53红色-->
          <div v-if="item.prop==='status'" :style="{color:scope.row.status===0?'#555':'#FF4B53'}">
            {{ scope.row.status===0?'启用':'停用' }}
          </div>
          <!-- 抄读闸控/保电管理-通讯状态 -->
          <div v-else-if="item.prop==='comState'" class="round_flex">
            <div v-if="scope.row.comState===0" class="round_color" style="background-color: #ff4b53;" />
            <div v-if="scope.row.comState===1" class="round_color" style="background-color: #56b502;" />
            {{ (scope.row.comState===1||scope.row.comState===0)?scope.row.comState===1?'在线':'离线':'-' }}
          </div>
          <!-- 抄读闸控/保电管理-闸控状态 -->
          <div v-else-if="item.prop==='brakeControl'" class="round_flex">
            <div v-if="scope.row.brakeControl===1" class="round_color" style="background-color: #ff4b53;" />
            <div v-if="scope.row.brakeControl===0" class="round_color" style="background-color: #56b502;" />
            {{ (scope.row.brakeControl===1||scope.row.brakeControl===0)?scope.row.brakeControl===1?'断闸':'合闸':'-' }}
          </div>
          <!-- 保电管理-是否保电 -->
          <div v-else-if="item.prop==='continuous'">
            {{ (scope.row.continuous===1||scope.row.continuous===0)?scope.row.continuous===1?'是':'否':'-' }}
          </div>
          <!-- 退费管理-处理状态-->
          <!-- 0待处理-蓝色  3仲裁中-红色 2已取消-灰色 1待确认-黄色 4已完成-绿色 -->
          <div v-else-if="item.prop==='recordState'" class="round_flex">
            <div v-if="scope.row.recordState===0" class="round_color" style="background-color: #4b88ff;" />
            <div v-if="scope.row.recordState===1 " class="round_color" style="background-color: #e6ad00;" />
            <div v-if="scope.row.recordState===2" class="round_color" style="background-color: #ccc;" />
            <div v-if="scope.row.recordState===3" class="round_color" style="background-color: #ff4b53;" />
            <div v-if="scope.row.recordState===4" class="round_color" style="background-color: #56b502;" />
            {{
            (scope.row.recordState===0?'待处理':(scope.row.recordState===1?'待确认':(scope.row.recordState===2?'已取消':(scope.row.recordState===3?'仲裁中':'已完成'
            )))) }}
          </div>
          <div v-else-if="item.prop === 'transactionStateName'" class="round_flex">
            <div v-if="scope.row.transactionState===1" class="round_color" style="background-color: #66bf16;" />
            <div v-if="scope.row.transactionState===2" class="round_color" style="background-color: #4b88ff;" />
            <div v-if="scope.row.transactionState===3" class="round_color" style="background-color: #ff4b53;" />
            <div v-if="scope.row.transactionState===4" class="round_color" style="background-color: #ccc;" />
            <div v-if="scope.row.transactionState===5" class="round_color" style="background-color: orange;" />
            {{ scope.row.transactionStateName }}
          </div>
          <div v-else-if="item.prop==='gender'">
            {{ (scope.row.gender=== 1||scope.row.gender=== 2)?scope.row.gender===1? '男':'女':'-' }}
          </div>
          <!-- buildingFullName建筑全路径页面-的建筑名称buildingName-在columns中添加page字段用来和不同页面的buildingName做区分 -->
          <div v-else-if="item.prop==='buildingName'&&item.page==='buildingFullName'">
            <el-popover placement="top" title="" popper-class="ReadingSettlementReport-popover" trigger="hover"
              :content="scope.row.buildingFullName">
              <!-- 显示最后一级建筑名称，鼠标悬浮展示完整路径 -->
              <div slot="reference">
                {{ scope.row.buildingName }}
              </div>
            </el-popover>
          </div>
          <div v-else-if="item.prop==='energyType'||item.prop==='deviceType'">
            {{ scope.row.energyType===1||scope.row.deviceType===1?'电':'-' }}
          </div>
          <div v-else-if="item.prop==='operateType'">
            {{ (scope.row.operateType===1||scope.row.operateType===2)?scope.row.operateType===1?'新建':'修改':'-' }}
          </div>
          <div v-else-if="item.prop==='paidInRelativeRatio'">
            <i v-if="scope.row.paidInRelativeRatio>=0" class="el-icon-top" style="color: red;" />
            <i v-if="scope.row.paidInRelativeRatio<0" class="el-icon-bottom" style="color: rgb(86 181 2);" />
            &nbsp;
            {{ scope.row.paidInRelativeRatio }}
          </div>
          <!-- 电价方案下发记录 -->
          <div v-else-if="item.prop==='energyPriceStatus'">
            {{
            (scope.row.energyPriceStatus===1||scope.row.energyPriceStatus===2)?scope.row.energyPriceStatus===1?'下发中':'已完成':'下发失败'
            }}
          </div>
          <!-- 缴费管理-二维码 -->
          <div v-else-if="item.prop==='accountState'">
            <div v-if="scope.row.deviceNumber">
              <el-popover placement="bottom" popper-class="popover" trigger="click">
                <div style="font-weight: bold;">
                  电费充值
                </div>
                <div>
                  <img :src="qrCodeSrc" class="Popover_img">
                  <div style="font-size: 14px;color: #555;">
                    微信扫一扫，充值电费
                  </div>
                </div>
                <div slot="reference">
                  <el-button type="text" class="RechargeCode" @click="handleRechargeCodePay(scope.row)">
                    充值码
                  </el-button>
                </div>
              </el-popover>
            </div>
            <div v-else>
              -
            </div>
          </div>
          <!-- 电表管理-二维码 -->
          <div v-else-if="item.prop==='qrCode'">
            <div v-if="scope.row.buildingId">
              <el-popover placement="bottom" popper-class="popover" trigger="click">
                <div style="font-weight: bold;">
                  电费充值
                </div>
                <div>
                  <img :src="qrCodeSrc" class="Popover_img">
                  <div style="font-size: 14px;color: #555;">
                    微信扫一扫，充值电费
                  </div>
                </div>
                <div slot="reference">
                  <el-button type="text" class="RechargeCode" @click="handleRechargeCode(scope.row)">
                    充值码
                  </el-button>
                </div>
              </el-popover>
            </div>
            <div v-else>
              -
            </div>
          </div>
          <!-- 抄读结算报表-用电量悬浮展示明细 -->
          <!-- <div v-else-if="item.prop==='totalUseEp'">
            <div v-if="scope.row.totalUseEp !==null">
              <el-popover placement="left"
                          popper-class="reading_popover"
                          trigger="hover">
                <div class="totalUseEp">
                  <div style="font-weight: bold;">
                    总：{{ scope.row.totalUseEp }}
                  </div>
                  <div>尖：{{ scope.row.rate1UseEp===null?'-':scope.row.rate1UseEp }}</div>
                  <div>峰：{{ scope.row.rate2UseEp===null?'-':scope.row.rate2UseEp }}</div>
                  <div>平：{{ scope.row.rate3UseEp===null?'-':scope.row.rate3UseEp }}</div>
                  <div>谷：{{ scope.row.rate4UseEp===null?'-':scope.row.rate4UseEp }}</div>
                </div>
                <div slot="reference">
                  {{ scope.row.totalUseEp }}
                </div>
              </el-popover>
            </div>
            <div v-else>
              -
            </div>
          </div> -->
          <div v-else-if="item.prop.includes('timeFrameList')">
            <p v-for="time in scope.row[item.prop]" :key="time">
              {{ time }}
            </p>
          </div>
          <div v-else-if="item.prop === 'timeFrameName'">
            <el-tooltip effect="dark" :content="scope.row[item.prop]" placement="top-start">
              <p class="text-but">
                {{ scope.row[item.prop] }}
              </p>
            </el-tooltip>
            <el-popover placement="bottom" width="180" trigger="click">
              <div class="flex-box" v-for="(period, pIndex) in scope.row.periodInfo" :key="pIndex">
                <div>
                  {{ period.label }}:
                </div>
                <div>
                  <p v-for="(time, ind) in period.value" :key="ind">
                    {{ time }}
                  </p>
                </div>
              </div>
              <el-button slot="reference" type="text">
                查看
              </el-button>
            </el-popover>
          </div>
          <div v-else-if="item.prop === 'ip'">
            <p class="text-but" style="width: 110px;">
              {{ scope.row[item.prop] }}
            </p>
            <el-popover popper-class="dark-pop" placement="top" width="430" trigger="click">
              <p>
                {{ scope.row.browser }}
              </p>
              <el-button slot="reference" type="text">
                查看
              </el-button>
            </el-popover>
          </div>
          <div v-else>
            {{
              scope.row[scope.column.property]===null||scope.row[scope.column.property]===undefined||scope.row[scope.column.property]===""?'-':scope.row[scope.column.property]
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="operates.length>0" label="操作" width="240">
        <template slot-scope="scope">
          <div v-if="scope.row.self!==1" class="operates">
            <div v-for="item in operates" :key="item.prop">
              <!-- recharge:（1）建筑是否绑定电表 （2）电表激活后 -->
              <el-button v-if="item.prop==='recharge' && scope.row.recharge " type="text"
                @click="handleClick(scope.row,item.prop)">
                {{ item.name }}
              </el-button>
              <!-- 缴费管理-操作 -->
              <el-button
                v-if="item.prop==='shortMessage'&&(scope.row.balance <= 0)&&(scope.row.balance !== null)&&(scope.row.comState === 1)"
                type="text" :disabled="!scope.row.remind" @click="handleClick(scope.row,item.prop)">
                短信催缴
              </el-button>
              <!-- 退费管理-操作 -->
              <!-- 0待处理-蓝色  3仲裁中-红色 2已取消-灰色 1待确认-黄色 4已完成-绿色 -->
              <template v-else-if="item.prop==='uploadRefundVoucher'">
                <el-button v-if="(scope.row.recordState===0||scope.row.recordState===3) && scope.row.refundAmount !==''"
                  type="text" :disabled="(!scope.row.isGetAmountEnable)&& (scope.row.refundAmount === null)"
                  @click="handleClick(scope.row,item.prop)">
                  <!-- 有退费金额就显示"上传退费凭证",没有拿到退费金额就显示"获取退费金额","获取退费金额"一天只能点一次，点击一次置灰 -->
                  {{ (scope.row.refundAmount !== null) ?'上传退费凭证':'获取退费金额' }}
                </el-button>
              </template>

              <!-- 缴费管理-在线充值管理-付费凭证-->
              <div v-else-if="item.prop==='certificateOpen'&&scope.row.payAmount <0"
                style="position: relative;height: 34px;" />
              <!-- 缴费管理-在线充值管理-付费凭证-->
              <div v-else-if="item.prop==='certificateOpen' &&scope.row.payAmount >0"
                style="position: relative;height: 34px;">
                <el-button type="text" style="position: absolute;top: 0; left: 0;font-size: 14px;">
                  付费凭证
                </el-button>
                <el-image style="width: 58px; height: 34px; opacity: 0;" :src="certificateOpenUrl"
                  :preview-src-list="imgUrlList" @click.native="handleClick(scope.row,item.prop)" />
              </div>
              <!-- 缴费管理-在线充值管理-下载凭证-->
              <div v-else-if="item.prop==='certificateDownload'&&scope.row.payAmount <0"
                style="position: relative;height: 34px;" />
              <!-- 抄读闸控-操作 -->
              <el-button v-else-if="item.prop==='switchOff'&&scope.row.comState===1" type="text"
                @click="handleClick(scope.row,item.prop)">
                {{ scope.row.brakeControl===1?'合闸':'断闸' }}
              </el-button>
              <el-button v-else-if="item.prop==='copy'&&scope.row.comState===1" type="text"
                @click="handleClick(scope.row,item.prop)">
                抄读
              </el-button>
              <!-- 抄读闸控-保电操作 -->
              <el-button v-else-if="item.prop === 'continuous' && scope.row.comState === 1" type="text"
                @click="handleClick(scope.row, item.prop)">
                {{ scope.row.continuous === 1 ? '取消保电' : '保电' }}
              </el-button>
              <!-- 保电管理-操作 -->
              <el-button v-else-if="item.prop==='powerProtection'&&scope.row.continuous!==null" type="text"
                @click="handleClick(scope.row,item.prop)">
                {{ scope.row.continuous===1?'取消保电':'保电' }}
              </el-button>
              <!-- 异常事件 -->
              <el-button v-else-if="item.prop==='switchOn'&&scope.row.alarmType!==null" type="text"
                :disabled="item.prop==='switchOn' && scope.row.alarmType === 1"
                @click="handleClick(scope.row,item.prop)">
                {{ scope.row.alarmType==='0101001d'?'合闸':'断闸' }}
              </el-button>
              <!-- 电价方案下发记录 -->
              <el-button v-else-if="item.prop==='isSend'&&scope.row.canSend" type="text"
                @click="handleClick(scope.row,item.prop)">
                重新发送
              </el-button>
              <!-- 充值退费记录 下载凭证 -->
              <el-button v-else-if="(item.prop === 'downCertificate' && scope.row.transactionState === 1)" type="text"
                @click="handleClick(scope.row,item.prop)">
                {{ item.name }}
              </el-button>
              <!-- 充值退费记录 手动退费 -->
              <el-button v-else-if="item.prop === 'manual-refund' && scope.row.manualRefundEnable === 1" type="text"
                @click="handleClick(scope.row, item.prop)">
                {{ item.name }}
              </el-button>
              <!-- 充值退费记录 查看手动退费 -->
              <el-button v-else-if="item.prop === 'check-manual-refund' && scope.row.checkManualRefundEnable === 1"
                type="text" @click="handleClick(scope.row, item.prop)">
                {{ item.name }}
              </el-button>
              <!-- 时段下发记录-重新发送 -->
              <el-button v-else-if="item.prop === 'retry-issue' && scope.row.retry === 1" type="text"
                @click="handleClick(scope.row, item.prop)">
                {{ item.name }}
              </el-button>
              <el-button
                v-else-if="!['recharge', 'shortMessage', 'switchOff', 'copy', 'powerProtection','continuous', 'switchOn', 'uploadRefundVoucher', 'isSend', 'downCertificate', 'retry-issue','manual-refund','check-manual-refund'].includes(item.prop)"
                type="text" @click="handleClick(scope.row,item.prop)">
                <!-- 用户管理 -->
                <div v-if="item.prop==='deactivate'">
                  {{ scope.row.status===0?'停用':'启用' }}
                </div>
                <div v-else
                  :style="(operates.some(ops => ops.prop === 'deactivate') && (item.prop !== 'deactivate' && item.prop !== 'delete') && scope.row.status === 1) ? 'display: none' : ''">
                  {{ item.name }}
                </div>
              </el-button>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination v-if="isPagination" :current-page="pageNum" :page-sizes="[5, 20, 50, 100]" :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="Number(total)" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" />
  </div>
</template>
<script>
const elementResizeDetectorMaker = require("element-resize-detector")
import MockData from "../mock/columns"
import { creatQrCode } from "@/utils"

export default {
  name: "JyTable",
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    columns: {
      type: Array,
      default: () => {
        return []
      }
    },
    operates: {
      type: Array,
      default: () => {
        return []
      }
    },
    customTableList: {
      type: Array,
      default: () => {
        return []
      }
    },
    pageNum: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 20
    },
    total: {
      default: 0
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    isPagination: {
      type: Boolean,
      default: true
    },
    imgUrlList: {
      type: Array,
      default: () => {
        return []
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    tableSummary: {
      type: String,
      default: ""
    },
    statistics: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      certificateOpenUrl: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      height: 0,
      currentPage: 1,
      Selected: "",
      cols: [],
      qrCodeSrc: ""
    }
  },
  watch: {
    columns () {
      this.setCols()
    }
  },
  mounted () {
    this.watchSize()
    this.setCols()
  },
  updated () {
    this.$nextTick(() => {
      this.$refs["multipleTable"].doLayout()
    })
  },
  methods: {
    // 合计逻辑
    getSummaries (param) {
      console.log("合计逻辑", param, this.statistics)
      const { columns } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计"
          return
        }
        // 缴费统计&费用明细中不需要统计的列表项
        columns.forEach((column, index) => {
          if (column.label === "统计时间" || column.label === "实缴环比(%)" || column.label === "实缴同比(%)" || column.label === "端口" || column.label === "操作人" || column.label === "手续费(%)") {
            sums[index] = "-"
            return
          }
          if (column.label === "充值金额(元)") {
            sums[index] = this.statistics?.chargerAmount
            return
          }
          if (column.label === "退费金额(元)") {
            sums[index] = this.statistics?.refundAmount
            return
          }
          if (column.label === "交易笔数(笔)") {
            sums[index] = this.statistics?.transactionCount
            return
          }
          if (column.label === "实缴金额(元)") {
            sums[index] = this.statistics?.paidInAmount
            return
          }
          if (column.label === "交易笔数（笔）") {
            sums[index] = this.statistics?.transactionCount
            return
          }
          if (column.label === "金额(元)") {
            sums[index] = this.statistics?.amount
            return
          }
          if (column.label === "到账金额(元)") {
            sums[index] = this.statistics?.arrivalAmount
            return
          }
        })
      })

      return sums
    },
    watchSize () {
      console.log("operates", this.operates)
      const erd = elementResizeDetectorMaker()
      erd.listenTo(document.getElementById("table"), (element) => {
        this.height = element.offsetHeight
      })
    },
    setCols () {
      // console.log("子", this.$route.name, MockData[this.$route.name], this.customTableList)
      const arr = []
      this.customTableList.forEach(m => {
        MockData[this.$route.name].forEach(i => {
          if (m.fieldCode === i.fieldCode) {
            arr.push(i)
          }
        })
      })
      if (this.$route.name === "ElectricityMeter") {
        this.cols = arr
      } else {
        this.cols = this.columns.length > 0 ? this.columns : MockData[this.$route.name]
        console.log("this.cols", this.cols)
      }
    },
    handleClick (row, prop) {
      console.log("rowprop", prop)
      this.$emit(prop, row)
    },
    handleSizeChange (val) {
      this.$emit("changeSize", val)
    },
    handleCurrentChange (val) {
      this.$emit("changeNum", val)
    },
    handleSelectionChange (selection) {
      this.$emit("selection-change", selection)
    },
    handleSelect (select) {
      this.$emit("select", select)
    },
    handleSelectAll (select) {
      this.$emit("select-all", select)
    },
    handleRechargeCode (val) {
      console.log("val", val)
      this.qrCodeSrc = val.deviceNumber ? creatQrCode(`${location.origin}/wexin/mp?name=qrcode&id=${val.buildingId}&deviceNumber=${val.deviceNumber}`) : ""
    },
    handleRechargeCodePay (val) {
      console.log("val", val)
      this.qrCodeSrc = val.deviceNumber ? creatQrCode(`${location.origin}/wexin/mp?name=qrcode&id=${val.id}&deviceNumber=${val.deviceNumber}`) : ""
    }
  }
}
</script>
<style lang="scss" scoped>
.RechargeCode {
  font-size: 14px;
  color: #4b88ff;
}

.totalUseEp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 45px 0 10px;
}

:deep(.el-pager li.active) {
  color: #4b88ff;
}

:deep(.el-pager li:hover) {
  color: #4b88ff;
}

.jy-table {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: space-between;

  // height: 100%;
  // min-height: calc(100% - 53px);
  .el-table {
    :deep(.cell) {
      font-size: 14px;
      white-space: nowrap;

      div {
        overflow: hidden;
        font-size: 14px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    :deep(.el-button--small) {
      font-size: 14px;
    }
  }

  .operates {
    display: flex;
    flex-direction: row;
    // gap: 10px;
    justify-content: flex-start;

    :deep(.el-button--text) {
      color: #4b88ff;
      margin-right: 10px;
    }

    :deep(.is-disabled) {
      color: #c0c4cc;
    }
  }
}

.Popover_img {
  width: 180px;
  height: 180px;
  margin: 20px auto 10px;
}

.round_color {
  width: 12px;
  height: 12px;
  border-radius: 100px;
}

.round_flex {
  display: flex;
  gap: 10px;
  align-items: center;
}

</style>

<style scoped>
@import '../assets/styles/components.css';
</style>

<style lang="scss">
// 列表悬浮展示黑色背景透明度60%

// 抄读结算悬浮窗口
.ReadingSettlementReport-popover {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10px;
  font-size: 12px;
  color: white;
  text-align: center;
  background-color: #000;
  opacity: 0.7;
  .popper__arrow {
      &::after {
        border-top-color: #000 !important;
      }
    }
}

// .ReadingSettlementReport-popover[x-placement^='top'] .popper__arrow {
//   border-top-color: rgb(48 49 51 / 30%) !important;
// }

// .ReadingSettlementReport-popover[x-placement^='top'] .popper__arrow::after {
//   border-top-color: rgb(48 49 51 / 30%) !important;
// }

// 二维码悬浮框
.el-tooltip__popper {
  max-width: 30%;
}

.el-popover.popover {
  width: 268px;
  height: 286px;
  font-size: 16px;
  text-align: center;
}

.reading_popover {
  width: auto;
  height: 121px;
  font-size: 14px;
  color: #555;
}
.text-but {
  display: inline-block;
  width: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 34px;
  line-height: 34px;
  vertical-align: middle;
}
.flex-box {
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
}
.dark-pop {
  opacity: 0.7;
  background: #000;
  color: #fff;
  .popper__arrow {
    &::after {
      border-top-color: #000!important;
    }
  }
}
</style>
