import { EnergyType, Gender } from "./index"
const PayRefundRecord = [
  { label: "处理描述", value: "content", type: "textarea" },
  { label: "凭证", value: "voucherUrl", type: "imgPre" }
]

const RefundManagement = [
  { label: "建筑名称", value: "buildingName" },
  { label: "房客姓名", value: "proposerName" },
  { label: "手机号", value: "proposerTel" },
  { label: "电表号", value: "deviceNumber" },
  { label: "退款发起时间", value: "recordCreateTime" },
  { label: "退款金额(元)", value: "refundAmount" },
  { label: "备注", value: "content", type: "textarea" },
  { label: "预计退费金额(元)", value: "estimateAmount" },
  { label: "处理状态", value: "recordState" },
  { label: "最近操作时间", value: "recordUpdateTime" }
]

const AlarmRules = [
  { label: "告警规则名称", value: "eventName" },
  { label: "等级", value: "alarmLevelName" },
  { label: "规则描述", value: "eventDesc" },
  { label: "大类", value: "eventTypeName" },
  { label: "推送规则", value: "pushRuleName" },
  { label: "关联对象", value: "deviceName", type: "textarea", autosize: 15 }
]

const PushRules = [
  { label: "推送规则名称", value: "ruleName" },
  { label: "关联状态", value: "relatedStateName" },
  { label: "备注", value: "comment", type: "textarea" },
  { label: "创建人", value: "createUserName" },
  { label: "创建时间", value: "createTime" },
  { label: "操作人", value: "updateUserName" },
  { label: "最近操作时间", value: "updateTime" },
  { label: "推送对象", value: "notifyUser", type: "textarea" },
  { label: "被关联告警规则", value: "eventRuleName", type: "textarea" }
]

const CostRules = [
  { label: "费控规则名称", value: "eventName" },
  { label: "规则名称", value: "ruleTypeName" },
  { label: "规则描述", value: "eventDesc" },
  { label: "创建人", value: "createUserName" },
  { label: "创建时间", value: "createTime" },
  { label: "操作人", value: "updateUserName" },
  { label: "最近操作时间", value: "updateTime" },
  { label: "关联区域", value: "relatedBuildingNames" }
]

const ElectricityMeter = [
  { label: "电表号", value: "deviceNumber" },
  { label: "电表类型", value: "deviceTypeName" },
  { label: "能源类型", value: "energyType", type: "select", options: EnergyType },
  { label: "通讯地址", value: "comAddress" },
  { label: "CT变比", value: "ratio" },
  { label: "最大功率", value: "maxPower" },
  { label: "通讯类别", value: "comTypeNameList" },
  { label: "预留金额", value: "initAmount" },
  { label: "充值二维码", value: "qrCode", type: "img" },
  { label: "绑定建筑", value: "buildingName", type: "textarea" },
  { label: "电价", value: "price", type: "textarea" },
  { label: "费控规则", value: "eventRule" },
  { label: "备注", value: "comment", type: "textarea" },
  { label: "创建人", value: "createUserName" },
  { label: "创建时间", value: "createTime" },
  { label: "操作人", value: "updateUserName" },
  { label: "最近操作时间", value: "updateTime" },
  {
    label: "租户信息",
    value: "tenantList",
    type: "table",
    columns: [
      { prop: "fullName", label: "姓名" },
      { prop: "genderStr", label: "性别" },
      { prop: "phoneNum", label: "手机号" }
      // { prop: "comment", label: "备注" }
    ]
  }
]

const ElectricityPrice = [
  { label: "收费方案名称", value: "energyPriceName" },
  { label: "能源类型", value: "energyType", type: "select", options: EnergyType },
  { label: "方案详情", value: "price", type: "textarea" },
  { label: "创建人", value: "createUserName" },
  { label: "创建时间", value: "createTime" },
  { label: "操作人", value: "updateUserName" },
  { label: "最近操作时间", value: "updateTime" }
]

const TenantManagement = [
  { label: "账号", value: "phoneNum" },
  { label: "姓名", value: "fullName" },
  { label: "性别", value: "gender", type: "select", options: Gender },
  { label: "电子邮箱", value: "email" },
  { label: "添加人", value: "createUserName" },
  { label: "添加时间", value: "createTime" },
  {
    label: "租用建筑",
    value: "businessRelationshipRequestList",
    type: "table",
    columns: [
      { prop: "buildingName", label: "建筑名称" },
      { prop: "isHasRight", label: "闸控开关权限" },
      { prop: "comment", label: "备注" }
    ]
  }
]

const RoleManagement = [
  { label: "角色名", value: "roleName" },
  // { label: "状态", value: "status" },
  { label: "创建时间", value: "createTime" },
  // { label: "修改人", value: "updateUserName" },
  { label: "最近操作时间", value: "updateTime" },
  { label: "描述", value: "roleDesc", type: "textarea" },
  {
    label: "用户",
    value: "userList",
    type: "table",
    columns: [
      { prop: "userName", label: "用户名" },
      { prop: "phoneNum", label: "手机号" },
      { prop: "fullName", label: "姓名" }
    ]
  }]

const UserManagement = [
  { label: "用户名", value: "userName" },
  { label: "手机号", value: "phoneNum" },
  { label: "姓名", value: "fullName" },
  { label: "性别", value: "gender", type: "select", options: Gender },
  { label: "电子邮箱", value: "email" },
  { label: "备注", value: "comment", type: "textarea" },
  { label: "选择角色", value: "roleIdList", type: "checkbox", defaultProps: { label: "roleName", value: "roleId" }}
]

const RateTimeConfig = [
  { label: "时段名称", value: "name", maxlength: 20 },
  { label: "创建人", value: "creatorName" },
  { label: "创建时间", value: "creatorTime" },
  { label: "操作人", value: "operatorName" },
  { label: "最后操作时间", value: "operateTime" },
  {
    label: "noShow",
    value: "period",
    type: "table",
    columns: [
      { prop: "periodName", label: "时段", width: 90 },
      { prop: "period0", label: "第一时段" },
      { prop: "period1", label: "第二时段" },
      { prop: "period2", label: "第三时段" },
      { prop: "period3", label: "第四时段" },
    ]
  }
]
export default {
  PayRefundRecord,
  RefundManagement,
  AlarmRules,
  PushRules,
  CostRules,
  ElectricityMeter,
  ElectricityPrice,
  TenantManagement,
  RoleManagement,
  UserManagement,
  RateTimeConfig
}
