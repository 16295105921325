import { getAccessToken, getCompanyId, getPassword } from "@/utils/auth"
import store from "@/store"
// 白名单
const whiteList = ["/password-login", "/password-forgetting"]

export default function setupRouterGuard (router) {
  router.beforeEach((to, _, next) => {
    const hasToken = getAccessToken()
    const hasCompanyId = getCompanyId()
    const hasPassword = getPassword()

    // fix bug: https://ukia20220713033308660.pingcode.com/pjm/items/63466c44a83a7825ddc359bd
    if (hasToken) {
      //   初始密码并且存在企业ID 才能登录
      if (hasCompanyId && hasPassword) {
        // 登录页面重定向到默认页面
        const isLoginPage = to.name === "PasswordLogin"
        if (isLoginPage) {
          next({ path: localStorage.getItem("defaultActive") })
        } else {
          const menus = JSON.parse(localStorage.getItem("menuRoutes"))

          if (to.path === "/personal-information") { next() }
          if (to.path === "/") {
            if (menus.some(m => m.resourceUrl === to.path)) { next() }
          } else {
            menus.map(menu => {
              if (menu.children && menu.children.some(m => m.resourceUrl === to.path)) { next() }
            })
          }
          next()
        }
      } else if (to.name === "PersonalInformation" || to.name === "PasswordSetting") {
        //  个人中心页面放行
        next()
      } else {
        store.dispatch("user/resetToken")
        next()
      }
    } else {
      if (whiteList.indexOf(to.path) !== -1) {
        next()
      } else {
        next("/password-login")
      }
    }
  })
}

