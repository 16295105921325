<template>
  <div class="jy-dialog">
    <el-dialog :title="title"
               :visible.sync="dialogVisible"
               :width="width"
               :top="top"
               :before-close="onCancel"
               :close-on-click-modal="closeOnClickModal"
               :destroy-on-close="destroyOnClose">
      <slot />
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel">取消</el-button>
        <el-button type="primary" @click="onConfirm">{{ butWord }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "JyDialog",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    width: {
      type: String,
      default: "320px"
    },
    top: {
      type: String,
      default: "42vh"
    },
    modal: {
      type: Boolean,
      default: true
    },
    closeOnClickModal: {
      type: Boolean,
      default: true
    },
    destroyOnClose: {
      type: Boolean,
      default: false
    },
    butWord: {
      type: String,
      default: "确认"
    }

  },
  data () {
    return {
      dialogVisible: false
    }
  },
  watch: {
    visible: function (val, oldVal) {
      this.dialogVisible = val
    }
  },
  methods: {
    onConfirm () {
      this.$emit("confirm")
    },
    onCancel () {
      this.$emit("cancel")
    }
  }
}
</script>
<style lang="scss" scoped>
.jy-dialog {
  :deep(.el-dialog) {
    // .el-dialog__header {
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    //   height: 40px;
    //   padding: 0 14px;
    //   background: #4b88ff;

    //   .el-dialog__headerbtn {
    //     position: relative;
    //     top: 0;
    //     right: 0;

    //     .el-dialog__close {
    //       color: #fff;
    //     }
    //   }
    // }

    // .el-dialog__body {
    //   padding: 20px 15px;
    //   font-size: 14px;
    // }

    // .el-dialog__footer {
    //   padding: 5px 15px 10px;
    // }
    // 弹窗样式
    .el-dialog__header {
      padding: 12px 20px;
      margin-right: 0;
      line-height: 16px;
      background-color: $color-primary;

      .el-dialog__title {
        font-size: 16px;
        line-height: 16px;
        color: $color-white;
      }

      .el-dialog__headerbtn {
        top: 2px;
        height: 40px;
        line-height: 40px;

        .el-dialog__close {
          color: $color-white;
        }
      }
    }

    .el-dialog__body {
      padding: 20px;

      .el-form {
        .el-form-item {
          margin-bottom: 15px;
        }
        .el-form-item:last-child {
          margin-bottom: 0;
        }
      }
    }

    .el-dialog__footer {
      padding-top: 0;
    }
  }
}
</style>
