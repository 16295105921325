import Vue from "vue"
import Vuex from "vuex"
import user from "./user.module"

Vue.use(Vuex)

export default new Vuex.Store({
  getters: {
    accessToken: state => state.user.accessToken,
    refreshToken: state => state.user.refreshToken,
    tokenType: state => state.user.tokenType,
    expirationIn: state => state.user.expirationIn,
    gender: state => state.user.gender
    // companyList: state => state.user.companyList
  },
  modules: {
    user
  }
})
